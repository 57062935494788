#footer .content {
    // padding: 20px;
    font-size: 0.75em;
    color: #fff;
    height: 100%;
}


#footer .content .projetos {
    display: flex;
    height: 90%;
    width: 100%;
    padding-top: 20px;
    align-items: center;
    overflow: hidden;
}

#footer .content .projetos .projetos_inner{
    display: flex;align-items: center;justify-content: center;
    position: relative;
    height: 100%;
    // min-width: 100%;
    // width: auto;
}

#footer .content .projetos .projetos_inner .projeto {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    min-width: 400px;

    &:not(:last-child){
        margin-right: 50px;
    }
}